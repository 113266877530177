import call_to_action from '../../../assets/images/call-to-action.svg';
import call_to_action_mobile from '../../../assets/images/call-to-action-mobile.svg';
import './solution_five.css';



function SolutionFive() {
  return (
        <div className='container pt-5'>
            <br />
            <br />
            <br className='d-none d-md-block'/>
            <br className='d-none d-md-block'/>
            <center>
                <div className='d-md-none'>
                                <img className='' width='100%' src={call_to_action_mobile} alt="" srcSet="" />
                </div>
                <div className='d-none d-md-block'>
                                <img className='call-to-action-img' src={call_to_action} alt="" srcSet="" />
                </div>
            </center>
            <br />
            <br />
            <br />
            <br />
        </div>
  );
}

export default SolutionFive;