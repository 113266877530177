import './solution_one.css';

function SolutionOne() {
  return (
        <div className='container'>
            <div className='pt-5 d-md-none '/>
            <center>
                <div className="cb">
                    CROSS-BORDER TRANSACTION
                </div>
                <div className='sol-one-heading'>
                    Say Goodbye to Payment Hassles 
                </div>
                <div className="syj">
                    Start your journey to hassle free Cross-Border Payments with iNPAY.
                </div>
            </center>
        </div>
  );
}

export default SolutionOne;