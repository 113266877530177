import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';

// Import your component pages here
import LandingPage from './pages/landing';
import ContactPage from './pages/contact-us/index';
import AboutPage from './pages/about';

function App() {
  return (
    <Router>
      <Routes>  
        <Route path="/" element={<LandingPage />} />  
        <Route path="/contact-us" element={<ContactPage />} />
        <Route path="/about-us" element={<AboutPage />} />
      </Routes>
    </Router>
  );
}

export default App;
