import person from '../../../assets/images/person.svg';
import React, { useEffect, useState } from 'react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import './testimonials.css';


function Testimonials() {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 1023); // Adjust breakpoint as needed
    const [isTablet, setIsTablet] = useState(window.innerWidth <= 1199); // Adjust breakpoint as needed
    const [activeIndex, setActiveIndex] = useState(0);
    const handleSlideChange = (newIndex) => {
        setActiveIndex(newIndex); // update state if needed
      };

      

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 1023);
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);


  useEffect(() => {
    const handleResizeTablet = () => setIsTablet(window.innerWidth <= 1199);
    window.addEventListener('resize', handleResizeTablet);

    return () => window.removeEventListener('resize', handleResizeTablet);
  }, []);

  return (
    <center className='mt-5 pt-4'>
   
    

   <div className="icontainer2">
   <AliceCarousel
      mouseTracking
      items={items.map((slide, index) => (
        <div key={index} style={{ transform: !isMobile && index !== activeIndex  ? 'scale(0.7)' : ''}}>
          {slide}
        </div>
      ))}
      activeIndex={activeIndex}
      onSlideChanged={(e) => handleSlideChange(e.slide)}
      responsive={responsive}
      controlsStrategy="alternate"
      infinite
      autoPlayInterval={3000}
      paddingRight={isMobile?0:isTablet?(window.innerWidth/6):(window.innerWidth/5)}
      paddingLeft={isMobile?0:isTablet?(window.innerWidth/6):(window.innerWidth/5)}
      disableButtonsControls
      autoPlay
    
    />
   </div>
    </center>
  );
}

const responsive = {
  0: { items: 1 },
  768: {items: 1},
  1024: { items: 1 }
};



const items = [
    <div className="container">
       
      <div className="tcontainer">
  
          <div className="tmedia">
              <div className=''>
              <img className="tmedia-img" src={person} alt="Generic placeholder "/>
              </div>
              
          </div>
          <div className="tmedia">
              <div className="tmedia-info">
                  <h5 className="mt-0 poppins tmedia-body">John A.</h5>
                  <p className='tmedia-des'>Small Business Owner</p>
              </div>
          </div>
          <p className='tesimonial-des'>
          "I can't wait for iNPAY to launch! As a small business owner, managing payments can be a hassle. 
          iNPAY promises to streamline the process, making it easier and faster to handle transactions. 
          The convenience of a secure digital wallet is exactly what my business needs."
          </p>
          
      </div>
      </div>,
  <div className="container">
     
    <div className="tcontainer">

        <div className="tmedia">
            <div className=''>
            <img className="tmedia-img" src={person} alt="Generic placeholder "/>
            </div>
            
        </div>
        <div className="tmedia">
            <div className="tmedia-info">
                <h5 className="mt-0 poppins tmedia-body">Sarah B.</h5>
                <p className='tmedia-des'>Freelance Designer</p>
            </div>
        </div>
        <p className='tesimonial-des'>
        "I've been looking for a reliable way to manage my freelance payments, and iNPAY seems like the perfect solution. 
        The ability to send and receive money effortlessly within Nigeria is a game-changer. I'm excited to try out all the 
        features once it's live!"
        </p>
        
    </div>
    </div>,
  <div className="container">
     
    <div className="tcontainer">

        <div className="tmedia">
            <div className=''>
            <img className="tmedia-img" src={person} alt="Generic placeholder "/>
            </div>
            
        </div>
        <div className="tmedia">
            <div className="tmedia-info">
                <h5 className="mt-0 poppins tmedia-body">Emeka O.</h5>
                <p className='tmedia-des'>University Student</p>
            </div>
        </div>
        <p className='tesimonial-des'>
        "Balancing studies and finances can be tough, but iNPAY is set to make things a lot simpler. 
        From paying for goods and services to splitting bills with friends, this app covers all the bases. 
        I can't wait to use it and see how it enhances my financial management."

        </p>
        
    </div>
    </div>,
  <div className="container">
     
    <div className="tcontainer">

        <div className="tmedia">
            <div className=''>
            <img className="tmedia-img" src={person} alt="Generic placeholder "/>
            </div>
            
        </div>
        <div className="tmedia">
            <div className="tmedia-info">
                <h5 className="mt-0 poppins tmedia-body">Fatima K.</h5>
                <p className='tmedia-des'>Busy Professional</p>
            </div>
        </div>
        <p className='tesimonial-des'>
        "Time is money, and iNPAY is about to save me a lot of both. The promise of seamless transactions and a user-friendly interface is incredibly appealing. 
        I'm looking forward to having a reliable digital wallet to manage my expenses on the go."
        </p>
        
    </div>
    </div>,
  <div className="container">
     
    <div className="tcontainer">

        <div className="tmedia">
            <div className=''>
            <img className="tmedia-img" src={person} alt="Generic placeholder "/>
            </div>
            
        </div>
        <div className="tmedia">
            <div className="tmedia-info">
                <h5 className="mt-0 poppins tmedia-body">Chidi E.</h5>
                <p className='tmedia-des'>Tech Enthusiast</p>
            </div>
        </div>
        <p className='tesimonial-des'>
        "As someone who loves new technology, I'm really excited about iNPAY's launch. The features like QR code payments and virtual cards are innovative and practical. I can't wait to see how this app transforms digital payments in Nigeria!"
        </p>
        
    </div>
    </div>,

];


export default Testimonials;