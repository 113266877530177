import './solution_three.css';

function SolutionThree() {
  return (
        <div className='container pt-5'>
          <br className='d-md-none'/>
          <br className='d-md-none'/>
            <center>
                <div className="cb">
                TESTIMONIALS
                </div>
                <div className='sgb'>
                What Our Customers are Saying
                </div>
            </center>

        </div>
  );
}

export default SolutionThree;