import './faqs.css';
import expand_icon from '../../../assets/images/expand-icon.svg';
import collapse_icon from '../../../assets/images/collapse-icon.svg';
import { useState } from 'react';

function FaqItems({question, answer}) {
  const [isVisible, setIsVisible] = useState(false); 

  function changeState(){
    setIsVisible(!isVisible);
  }

  return (
        <div className='container'>
          <div className={!isVisible ? 'faq-container' : 'faq-container-answer'}>
            <center>
              <div className="col-sm-12 col-md-8 col-lg-7">
                <div className="" onClick={()=>changeState()}>
                <div className="faq-question" style={isVisible ? {color: 'white'} : {}}>{question}</div>
                {isVisible?<img src={collapse_icon} alt="" className="faq-arrow" />:<img src={expand_icon} alt="" className="faq-arrow" />}
                </div>
              </div>
              {isVisible && <div className="faq-answer">{answer}</div>}

            </center>
            </div>
        </div>
  );
}

export default FaqItems;