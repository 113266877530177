import walletScreen from '../../../assets/images/wallet-screen.svg';
import wallet_screen_mobile from '../../../assets/images/wallet-screen-mobile.png';
import qrScreen from '../../../assets/images/qr-screen.svg';
import qr_screen_mobile from '../../../assets/images/qr-screen-mobile.png';
import seamless_payment from '../../../assets/images/seamless-payment.svg';
import seamless_payment_mobile from '../../../assets/images/seamless-payment-mobile.png';
import value_added_services from '../../../assets/images/value-added-services.svg';
import value_added_services_mobile from '../../../assets/images/value-added-services-mobile.png';
import virtual_cards from '../../../assets/images/virtual-cards.svg';
import virtual_cards_mobile from '../../../assets/images/virtual-cards-mobile.png';
import './body_main.css';

function BodyMain() {
  return (
        <div className='container'>
            <br className='d-none d-md-block'/>
            <br className='d-none d-md-block'/>
            <br className='d-md-none'/>
            <br className='d-md-none'/>
            <div className="row">

                
                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 body-main-item center-cross">
                    <div className="body-main-title">Comprehensive Wallet Management</div>
                    <div className="body-main-des">Keep track of all your finances in one place with iNPAY’s wallet management feature. Monitor your spending, set budgets, and manage multiple accounts seamlessly within the app.</div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 d-none d-md-block">
                    <img src={walletScreen} alt="" className="body-main-item" />
                </div>
                <div className="col-sm-12 col-md-6 d-md-none">
                    <img src={wallet_screen_mobile} alt="" className="body-main-item2" />
                </div>

                <div className="col-12"><br/><br className='d-none d-md-block'/><br className='d-md-none'/></div>

                

                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 d-none d-md-block">
                    <img src={seamless_payment} alt="" className="body-main-item" />
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 body-main-item center-cross">
                    <div className="body-main-title">Seamless Transactions</div>
                    <div className="body-main-des">Conduct transactions effortlessly with iNPAY’s intuitive interface. Whether you’re paying bills, transferring money, or making purchases, our platform ensures a smooth, hassle-free experience every time.</div>
                </div>
                <div className='d-md-block'/>
                <br className='d-md-none'/>
                <div className="col-sm-12 col-md-6 d-md-none">
                    <img src={seamless_payment_mobile} alt="" className="body-main-item2" />
                </div>

                <div className="col-12"><br/><br className='d-none d-md-block'/><br className='d-md-none'/></div>


                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 body-main-item center-cross">
                    <div className="body-main-title">QR Code Payments</div>
                    <div className="body-main-des">Simplify your payments with QR code technology. Scan and pay instantly at participating merchants, making your checkout process faster and more convenient than ever.</div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 d-none d-md-block">
                    <img src={qrScreen} alt="" className="body-main-item" />
                </div>
                <div className="col-sm-12 col-md-6 d-md-none">
                    <img src={qr_screen_mobile} alt="" className="body-main-item2" />
                </div>


                <div className="col-12 d-md-none"><br/><br className='d-none d-md-block'/><br className='d-md-none'/></div>
                

                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 d-none d-md-block">
                    <img src={value_added_services} alt="" className="body-main-item" />
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 body-main-item center-cross">
                    <div className="body-main-title">Value-Added Services</div>
                    <div className="body-main-des">Enjoy additional benefits such as rewards, cashback offers, and discounts on partnered services. iNPAY goes beyond payments to provide you with value-added services that enhance your financial wellbeing.</div>
                </div>
                <div className='d-md-block'/>
                <br className='d-md-none'/>
                <div className="col-sm-12 col-md-6 d-md-none">
                    <img src={value_added_services_mobile} alt="" className="body-main-item2" />
                </div>



                <div className="col-12"><br/><br className='d-none d-md-block'/><br className='d-md-none'/></div>



                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 body-main-item center-cross">
                    <div className="body-main-title">Virtual Cards</div>
                    <div className="body-main-des"> Enhance your online shopping security with iNPAY’s virtual cards. Generate temporary card numbers for your transactions to protect your primary account information from fraud.</div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 d-none d-md-block">
                    <img src={virtual_cards} alt="" className="body-main-item" />
                </div>
                <div className="col-sm-12 col-md-6 d-md-none">
                    <img src={virtual_cards_mobile} alt="" className="body-main-item2" />
                </div>


                <div className="col-12 d-md-none"><br/><br/></div>

            </div>
        </div>
  );
}

export default BodyMain;