import React,{ useState } from 'react';
import inpay_special_phone_mobile from '../../../assets/images/inpay-special-phone-mobile.png';
import inpay_special_phone from '../../../assets/images/inpay-special-phone.svg';
import './solution_two.css';
import Waitlist from '../../../components/waitlist';



function SolutionTwo() {

  const [open, setOpen] = useState(false);
    
  function handleWaitlist ()  {
      return setOpen(!open);
  }

  return (
        <div className='container pt-5'>
            <br />
            <br />
            <center>
                
                <div className="inpay-special d-md-none">
        
              <div className="inpay-special-text-div">
                <p className="inpay-special-text-title"> iNPAY's Special</p>
                <p className="inpay-special-text-subtitle">One Solution for all your Cross-Border Payments.</p>
                <p className="inpay-special-text-body">Download the iNPAY app and enjoy the convenience of making payment and making informed decisions right at your fingertips.</p>
              </div>                
              
            
              <div className="inpay-special-text-button" >
                <button type="button" className="btn btn-large ibtn form-control whiteBg" style={{backgroundColor: "white"}} onClick = {() => handleWaitlist()}><p className='btn-des wl-btn whiteBg'>JOIN THE WAITLIST</p></button>
                <Waitlist setClicked={handleWaitlist} clicked = {open===true?"true":"false"}/> 
              </div>
              <div className='image-div'>
              <img  className='image-div' src={inpay_special_phone_mobile} alt=""/>
              </div>
            </div>
          

                
            </center>

          
              
            <div className="inpay-special d-none d-md-block">
        
              <div className="inpay-special-text-div">
                <p className="inpay-special-text-title"> iNPAY's Special</p>
                <p className="inpay-special-text-subtitle">One Solution for all your Cross-Border Payments.</p>
                <p className="inpay-special-text-body">Download the iNPAY app and enjoy the convenience of making payment and making informed decisions right at your fingertips.</p>
              </div>
                
              <img src={inpay_special_phone} alt=""/>
            
              <div className="inpay-special-text-button" >
                  <div className='pt-4 d-none d-md-block'/>

                <button type="button" className="btn btn-large ibtn form-control whiteBg" style={{backgroundColor: "white"}} onClick = {() => handleWaitlist()}><p className='btn-des wl-btn whiteBg'>JOIN THE WAITLIST</p></button>
                <Waitlist setClicked={handleWaitlist} clicked = {open===true?"true":"false"}/> 
              </div>
            </div>
        </div>
  );
}

export default SolutionTwo;